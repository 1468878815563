<template>
    <Toast />
    <br>
    <br>
    <p>Below are the core coaching needs that our research has identified.
    <b style="color: #38a3b3;">Please select two needs that you would be an expert in addressing, two
    needs where you have moderate experience and two minor needs</b>. This will
    help you get clients who are aligned with your skills and interests. We had
    client go through our proprietary test to help them understand what they
    want to get out of their coaching session. This is not the sole criteria
    for the curated profiles you will receive, so don’t worry about it reducing
    the breadth of your experience unduly.</p>

    <div class="justify-center q-main">
        <div class="grid flex">
            <div class="col-12 lg:col-6 xl:col-4 flex">
                <div class="card mb-0">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <span class="block text-blue-900 text-xl mb-3">Grow</span>
                        </div>
                    </div>
                    <div class="text-500">
                        The client wants to learn new skills, both soft and
                        hard. They are looking for a mentor to help them grow
                        beyond their current job and situation.
                    </div>
                    <div class="justify-center mt-2">
                        <Dropdown id="grow" v-model="dropdownGrow" :options="dropdownValues" optionLabel="name" placeholder="Select Priority" />
                    </div>
                </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-4 flex">
                <div class="card mb-0">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <span class="block text-blue-900 text-xl mb-3">Lead</span>
                        </div>
                    </div>
                    <div class="text-500">
                        The client wants to become a more effective leader. How
                        to gain respect from her team, to inspire others, to
                        lead become more assertive… Those are some concerns the
                        client wants to address
                    </div>
                    <div class="justify-center mt-2">
                        <Dropdown id="lead" v-model="dropdownLead" :options="dropdownValues" optionLabel="name" placeholder="Select Priority" />
                    </div>
                </div>
            </div>

            <div class="col-12 lg:col-6 xl:col-4 flex">
                <div class="card mb-0">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <span class="block text-blue-900 text-xl mb-3">Balance</span>
                        </div>
                    </div>
                    <div class="text-500">
                        The client wants to get a better balance between his
                        professional and personal life. The ability to create
                        meaningful connections in a world of remote working as
                        well as separating home from work when working from
                        home are also issues on their mind.
                    </div>
                    <div class="justify-center mt-2">
                        <Dropdown id="lead" v-model="dropdownBalance" :options="dropdownValues" optionLabel="name" placeholder="Select Priority" />
                    </div>
                </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-4 flex">
                <div class="card mb-0">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <span class="block text-blue-900 text-xl mb-3">Change</span>
                        </div>
                    </div>
                    <div class="text-500">
                        The client is ready to embark on a major change, be it
                        a career switch, a country move, or any major career
                        decision. Standing at the crossroad, he needs to get a
                        step back and think about the implication.
                    </div>
                    <div class="justify-center mt-2">
                        <Dropdown id="lead" v-model="dropdownChange" :options="dropdownValues" optionLabel="name" placeholder="Select Priority" />
                    </div>
                </div>
            </div>

            <div class="col-12 lg:col-6 xl:col-4 flex">
                <div class="card mb-0">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <span class="block text-blue-900 text-xl mb-3">Escape</span>
                        </div>
                    </div>
                    <div class="text-500">
                        The client is facing a difficult situation that
                        requires some short-term action. She feel stuck in her
                        job, faces a tough boss, doesn’t get along with her
                        colleagues… She wants to mend the situation, either by
                        leaving or by taking remediating measures.
                    </div>
                    <div class="justify-center mt-2">
                        <Dropdown id="lead" v-model="dropdownEscape" :options="dropdownValues" optionLabel="name" placeholder="Select Priority" />
                    </div>
                </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-4 flex">
                <div class="card mb-0">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <span class="block text-blue-900 text-xl mb-3">Reflect</span>
                        </div>
                    </div>
                    <div class="text-500">
                        The client wants to take the long view and understand
                        her deeper needs and wants, as well as triggers at
                        work. She wants to understand what deeply motivates her
                        and what her guiding principles are.
                    </div>
                    <div class="justify-center mt-2">
                        <Dropdown id="lead" v-model="dropdownReflect" :options="dropdownValues" optionLabel="name" placeholder="Select Priority" />
                    </div>
                </div>
            </div>
        </div>


        <div v-if="isDone" class="flex mt-2">
            <div v-if="external">
                <Button label="You're done! Click here to save." icon="pi pi-check" v-on:click="submit"></Button>
            </div>
            <div v-else>
                <Button label="You're done! Click here to continue to the last step." icon="pi pi-check" v-on:click="submit"></Button>
            </div>
        </div>
    </div>
</template>

<script>
import UserService from '../../service/UserService';

export default {
    props: {
        external: {
            default: false
        }
    },
    data() {
        return {
            dropdownValues: [
                {name: 'Core', code: 'core'},
                {name: 'Intermediate', code: 'intermediate'},
                {name: 'Minor', code: 'minor'}
            ],
            dropdownGrow: null,
            dropdownLead: null,
            dropdownBalance: null,
            dropdownChange: null,
            dropdownEscape: null,
            dropdownReflect: null,
        };
    },
    userService: null,
    created() {
        this.userService = new UserService();
    },
    computed: {
        isDone() {
            return this.dropdownGrow !== null && this.dropdownLead !== null && this.dropdownBalance !== null && this.dropdownChange !== null && this.dropdownEscape !== null && this.dropdownReflect !== null;
        }
    },
    methods: {
        update() {
            return this.userService.updateUser(
                {
                    "priorities": [
                        this.dropdownGrow['code'],
                        this.dropdownLead['code'],
                        this.dropdownBalance['code'],
                        this.dropdownChange['code'],
                        this.dropdownEscape['code'],
                        this.dropdownReflect['code']
                    ],
                    "coach": true
                }
            );
        },
        submit(event) {
            if (!this.isDone) {
                return;
            }
            var n_core = 0;
            var n_intermediate = 0;
            var n_minor = 0;

            var all_codes = [
                this.dropdownGrow['code'],
                this.dropdownLead['code'],
                this.dropdownBalance['code'],
                this.dropdownChange['code'],
                this.dropdownEscape['code'],
                this.dropdownReflect['code']
            ];

            for (var i in all_codes) {
                if (all_codes[i] == "core") {
                    n_core += 1;
                }
                if (all_codes[i] == "intermediate") {
                    n_intermediate += 1;
                }
                if (all_codes[i] == "minor") {
                    n_minor += 1;
                }
            }

            if (n_core != 2 || n_intermediate != 2 || n_minor != 2) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Two core priorities, two intermediate ones and two minor ones are required.',
                    life: 10000
                }); 
                return;
            }

            if (this.external) {
                this.update().then(() => {
                    this.$toast.add({severity:'info', summary: 'Successfully saved.', life: 3000}); 
                });
            } else {
                this.update().then(() => this.$router.push("/signupcoach/completion"));
            }
        },
    }
}
</script>
<style lang="scss">
@import "../../assets/styles/cards/mixins.scss";

.cards-main {
    height: 500px;
}
.card-hint {
    position: absolute;
    top: 45%;
    color: gray;
}
.q-main {
    flex-direction: column;
    align-items: center;
}
@media (max-width: 450px) {
    .hint-middle {
        left:40%!important;
    }
    .p-chart {
        width: 100%!important;
    }
}
</style>
