<template>
	<div class="justify-center">
		<div class="col-12 md:col-12">
			<div class="card card-w-title">
				<h5>Redo Priorities</h5>
				<Priorities :external=true />
			</div>
		</div>
	</div>
</template>

<script>
import Priorities from "@/components/c_signup_coaches/Priorities";
export default {
    components: {Priorities},
    data() {
        return {
        }
    },
}
</script>
<style lang="scss">
@media (max-width: 450px) {
    .layout-wrapper .layout-main-container {
        padding-left: 0rem!important;
    }
    .layout-main-container {
        padding: 7rem 0rem 2rem 0rem!important;
    }
}
</style>
